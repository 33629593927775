<script lang="ts" setup>
import TextInput from '@/components/inputs/TextInput.vue'
import OverlayBase from '@/components/overlays/OverlayBase.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'
import TwoColsBlock from '@/components/layout/TwoColsBlock.vue'
import SkrHeading from '@/components/SkrHeading.vue'

const { language } = storeToRefs(useLanguageStore())

const { createSnackbar } = useWidgetStore()

const { t } = useI18n()

const userStore = useUserStore()

defineProps({
  show: {
    type: Boolean,
    default: false,
  },
})

const { email: userEmail, hasSso } = storeToRefs(userStore)

const useEmailFlow = computed(() => hasSso.value)

const inputEmail = ref('')
const inputPassword = ref('')
const waitingForResponse = ref(false)

const disableSubmitButton = computed(() => {
  if (useEmailFlow.value) return userEmail.value !== inputEmail.value
  else return inputPassword.value === ''
})

const { userRepository } = useApi()

const deleteUser = async (): Promise<void> => {
  waitingForResponse.value = true
  try {
    await userRepository.delete(useEmailFlow.value ? undefined : inputPassword.value)
    sessionStorage.setItem('userHasJustBeenDeleted', 'true')
    await userStore.logoutUser(`/bye?lang=${language.value}`)
  } catch (error: unknown) {
    if ((error as { response?: { status: number } }).response?.status === 403) {
      createSnackbar({
        message: t('global.password_incorrect'),
      })
      waitingForResponse.value = false
    } else await navigateTo({ name: 'sorry' })
  }
}

const boldText = computed(() => {
  if (useEmailFlow.value) return t('delete_account.text.bold_email', { email: userEmail.value })
  else return t('delete_account.text.bold_password')
})
</script>

<template>
  <overlay-base>
    <two-cols-block class="fill-height" split="5/7">
      <template #left>
        <div class="mb-12">
          <skr-heading level="1" semantic>{{ t('delete_account.title') }}</skr-heading>
          <skr-heading level="2">
            {{ t('delete_account.text.upper') }}<br /><br /><strong>{{ boldText }}</strong></skr-heading
          >
        </div>
        <v-text-field
          v-if="useEmailFlow"
          v-model="inputEmail"
          variant="filled"
          :label="t('delete_account.input.label_email')"
          data-cy="email_input"
        />
        <text-input
          v-else
          v-model="inputPassword"
          type="password"
          data-cy="password_input"
          :label="t('delete_account.input.label_password')"
        />
        <v-btn
          block
          class="mx-0"
          color="error"
          size="large"
          :disabled="disableSubmitButton"
          :loading="waitingForResponse"
          data-cy="delete_account_confirmation_button"
          @click="deleteUser()"
        >
          {{ t('delete_account.cta.label') }}
        </v-btn>
        <div class="mt-4">
          <v-btn block variant="text" color="text" @click="$emit('close')">
            {{ t('global.no_thanks') }}
          </v-btn>
        </div>
      </template>
      <template v-if="$vuetify.display.mdAndUp" #right>
        <responsive-image source="delete-skribble" :width="565" :height="405" />
      </template>
    </two-cols-block>
  </overlay-base>
</template>
